import React from "react";
import Paragraph from "../../components/paragraph";
import SEO from "../../components/seo";
import Tier from "../../components/tier";
import WorkDetail from "../../components/work-detail";
import WorkDetailImage from "../../components/work-detail-image";
import WorkDetailIntro from "../../components/work-detail-intro";
import WorkDetailText from "../../components/work-detail-text";

import introImageSm from "../../images/work/portfolio/intro-sm.jpg";
import introImageMd from "../../images/work/portfolio/intro-md.jpg";
import introImageLg from "../../images/work/portfolio/intro-lg.jpg";
import contentImage1Sm from "../../images/work/portfolio/content-1-sm.jpg";
import contentImage1Md from "../../images/work/portfolio/content-1-md.jpg";
import contentImage1Lg from "../../images/work/portfolio/content-1-lg.jpg";
import contentImage2Sm from "../../images/work/portfolio/content-2-sm.png";
import contentImage2Md from "../../images/work/portfolio/content-2-md.png";
import contentImage2Lg from "../../images/work/portfolio/content-2-lg.png";
import contentImage3Sm from "../../images/work/portfolio/content-3-sm.jpg";
import contentImage3Md from "../../images/work/portfolio/content-3-md.jpg";
import contentImage3Lg from "../../images/work/portfolio/content-3-lg.jpg";
import contentImage4Sm from "../../images/work/portfolio/content-4-sm.jpg";
import contentImage4Md from "../../images/work/portfolio/content-4-md.jpg";
import contentImage4Lg from "../../images/work/portfolio/content-4-lg.jpg";
import contentImage5Sm from "../../images/work/portfolio/content-5-sm.jpg";
import contentImage5Md from "../../images/work/portfolio/content-5-md.jpg";
import contentImage5Lg from "../../images/work/portfolio/content-5-lg.jpg";
import contentImage6Sm from "../../images/work/portfolio/content-6-sm.jpg";
import contentImage6Md from "../../images/work/portfolio/content-6-md.jpg";
import contentImage6Lg from "../../images/work/portfolio/content-6-lg.jpg";
import contentImage7Sm from "../../images/work/portfolio/content-7-sm.jpg";
import contentImage7Md from "../../images/work/portfolio/content-7-md.jpg";
import contentImage7Lg from "../../images/work/portfolio/content-7-lg.jpg";
import contentImage8Sm from "../../images/work/portfolio/content-8-sm.png";
import contentImage8Md from "../../images/work/portfolio/content-8-md.png";
import contentImage8Lg from "../../images/work/portfolio/content-8-lg.png";
import contentImage9Sm from "../../images/work/portfolio/content-9-sm.png";
import contentImage9Md from "../../images/work/portfolio/content-9-md.png";
import contentImage9Lg from "../../images/work/portfolio/content-9-lg.png";
import contentImage10Sm from "../../images/work/portfolio/content-10-sm.png";
import contentImage10Md from "../../images/work/portfolio/content-10-md.png";
import contentImage10Lg from "../../images/work/portfolio/content-10-lg.png";
import contentImage11Sm from "../../images/work/portfolio/content-11-sm.png";
import contentImage11Md from "../../images/work/portfolio/content-11-md.png";
import contentImage11Lg from "../../images/work/portfolio/content-11-lg.png";
import contentImage12Sm from "../../images/work/portfolio/content-12-sm.png";
import contentImage12Md from "../../images/work/portfolio/content-12-md.png";
import contentImage12Lg from "../../images/work/portfolio/content-12-lg.png";
import contentImage13Sm from "../../images/work/portfolio/content-13-sm.png";
import contentImage13Md from "../../images/work/portfolio/content-13-md.png";
import contentImage13Lg from "../../images/work/portfolio/content-13-lg.png";
import contentImage14Sm from "../../images/work/portfolio/content-14-sm.png";
import contentImage14Md from "../../images/work/portfolio/content-14-md.png";
import contentImage14Lg from "../../images/work/portfolio/content-14-lg.png";
import contentImage15Sm from "../../images/work/portfolio/content-15-sm.png";
import contentImage15Md from "../../images/work/portfolio/content-15-md.png";
import contentImage15Lg from "../../images/work/portfolio/content-15-lg.png";

const ReportPage = () => (
  <>
    <SEO title="Portfolio Design" />
    <WorkDetail title="Portfolio Design">
      <WorkDetailIntro
        title="Designing a Responsive Website and Cohesive Personal Brand"
        description={
          <>
            <Paragraph variant="work-detail">
              The goal of this portfolio design was to have an effective way to
              showcase my design work, as well as create a personal brand that
              maintains a consistent visual language across my website and any
              print materials.
            </Paragraph>
          </>
        }
        images={{
          sm: introImageSm,
          md: introImageMd,
          lg: introImageLg,
          alt:
            "Tablet and phones floating in space with portfolio design on the screen.",
        }}
        role="Design"
        client="Personal project"
        project="portfolio"
      />
      <WorkDetailText heading="Logo Design">
        <Paragraph variant="work-detail">
          The first step of my portfolio design was to create my logo. My
          initial idea was an abstract ’M’ for my name, but I also wanted it to
          reflect my personality. The final logo represents that I take on
          design challenges and enjoy a design process that is a balance between
          structured and free-flowing.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage1Sm,
          md: contentImage1Md,
          lg: contentImage1Lg,
          alt: "Page from notebook with logo sketches.",
        }}
        narrowLg
      />
      <WorkDetailImage
        images={{
          sm: contentImage2Sm,
          md: contentImage2Md,
          lg: contentImage2Lg,
          alt: "Final vector logo.",
        }}
        narrowLg
      />
      <WorkDetailImage
        images={{
          sm: contentImage3Sm,
          md: contentImage3Md,
          lg: contentImage3Lg,
          alt: "Mockup of logo on a business card.",
        }}
        narrowLg
      />
      <WorkDetailText heading="Portfolio Website Design">
        <Paragraph variant="work-detail">
          After some sketching to work through ideas quickly, I moved onto a
          wireframe of the layout I felt was strongest.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        imagesLeft={{
          sm: contentImage4Sm,
          md: contentImage4Md,
          lg: contentImage4Lg,
          alt: "Page from notebook with layout sketches for website.",
        }}
        imagesRight={{
          sm: contentImage5Sm,
          md: contentImage5Md,
          lg: contentImage5Lg,
          alt: "Wireframes of website layout.",
        }}
        narrowLg
      />
      <WorkDetailText>
        <Paragraph variant="work-detail">
          I designed each page at desktop, tablet, and mobile breakpoints. I
          used a grid that would make it easy to code for a responsive layout.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage6Sm,
          md: contentImage6Md,
          lg: contentImage6Lg,
          alt: "Grid used on desktop, tablet, and mobile layouts.",
        }}
        fullWidth
      />
      <WorkDetailImage
        images={{
          sm: contentImage7Sm,
          md: contentImage7Md,
          lg: contentImage7Lg,
          alt: "Desktop, tablet, and mobile designs for webpages.",
        }}
        fullWidth
      />
      <WorkDetailText heading="Colors" tightBottom>
        <Paragraph variant="work-detail">
          In a master Sketch file, I compiled all the colors used throughout the
          site. This helped to streamline development and make sure there
          weren’t any arbitrary colors.
        </Paragraph>
        <Paragraph variant="work-detail">
          While designing the site, I tested the accessibility of color
          combinations used for buttons and text, making sure to comply with AA
          standard contrast ratios.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        imagesLeft={{
          sm: contentImage8Sm,
          md: contentImage8Md,
          lg: contentImage8Lg,
          alt: "Primary colors used for the website design.",
          heading: "Primary Colors",
        }}
        imagesRight={{
          sm: contentImage9Sm,
          md: contentImage9Md,
          lg: contentImage9Lg,
          alt: "Neutral colors used for the website design.",
          heading: "Neutral Colors",
        }}
        narrowLg
        pairStackedSmall
      />
      <WorkDetailImage
        imagesLeft={{
          sm: contentImage10Sm,
          md: contentImage10Md,
          lg: contentImage10Lg,
          alt: "Supporting colors used for the website design.",
          heading: "Supporting Colors",
        }}
        narrowLg
        pairStackedSmall
      />
      <Tier id="work-detail-callout">
        <WorkDetailText heading="Buttons" tightBottom>
          <Paragraph variant="work-detail">
            I provided the designs for the different button states, including
            custom focus states. By designing the focus states with good
            contrast, they stand out from the rest of the content to provide a
            better experience for keyboard users.
          </Paragraph>
        </WorkDetailText>
        <WorkDetailImage
          images={{
            sm: contentImage11Sm,
            md: contentImage11Md,
            lg: contentImage11Lg,
            alt: "The design for default button states.",
            heading: "Default",
          }}
          narrowLg
          variantSize="portfolio-buttons"
        />
        <WorkDetailImage
          images={{
            sm: contentImage12Sm,
            md: contentImage12Md,
            lg: contentImage12Lg,
            alt: "The design for button hover states.",
            heading: "Hover",
          }}
          narrowLg
          variantSize="portfolio-buttons"
        />
        <WorkDetailImage
          images={{
            sm: contentImage13Sm,
            md: contentImage13Md,
            lg: contentImage13Lg,
            alt: "The design for active button states.",
            heading: "Active",
          }}
          narrowLg
          variantSize="portfolio-buttons"
        />
        <WorkDetailImage
          images={{
            sm: contentImage14Sm,
            md: contentImage14Md,
            lg: contentImage14Lg,
            alt: "The design for button focus states.",
            heading: "Focus",
          }}
          narrowLg
          variantSize="portfolio-buttons"
        />
      </Tier>
      <WorkDetailText heading="Background Images">
        <Paragraph variant="work-detail">
          I created these vector background shapes to work with the webpage as
          it changes in size. As the width of the page grows or shrinks, more or
          less of the shape is revealed.
        </Paragraph>
      </WorkDetailText>
      <WorkDetailImage
        images={{
          sm: contentImage15Sm,
          md: contentImage15Md,
          lg: contentImage15Lg,
          alt: "Light purple amorphous cloud-like shapes.",
        }}
        narrowLg
      />
      <WorkDetailText heading="Development">
        <Paragraph variant="work-detail">
          I think open communication between designers and developers is
          important throughout the design process. This helps address any
          technical constraints or questions as early as possible. I used Zeplin
          as a tool for sharing my Sketch files and to give and receive feedback
          throughout the development process.
        </Paragraph>
        <Paragraph variant="work-detail">
          Designing for accessibility is also important to me. I used the WAVE
          Evaluation Tool to check for accessibility errors, and wrote alt text
          for all of the images. I also made sure the entire site could be
          navigated with a keyboard.
        </Paragraph>
      </WorkDetailText>
    </WorkDetail>
  </>
);

export default ReportPage;
